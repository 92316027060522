import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import usePlayer from "hooks/usePlayer"
import Logo from "components/svg/Logo"

const StyledRadioWrap = styled.div`
  width: 100%;
`

const StyledRadioTitle = styled.div`
  position: relative;
  background-color: rgba(170,170,170.95);
  backdrop-filter: var(--backdrop-filter);
  padding: 0.5em 0.75em;
  text-align: center;
  max-width: 8em;
  margin: 0 auto;
  border-radius: calc( 2* var(--border-radius)) calc(2* var(--border-radius)) 0 0;
  span {
    font-weight: 700;
    font-size: 0.875em;
    text-transform: uppercase;
  }
`

const StyledRadio = styled.div`
  position: relative;
  cursor: default;
  max-width: 38em;
  margin: 0 auto;
  width: 100%;
  background-color: rgba(255,255,255,0.9);
  border-radius: calc(4* var(--border-radius));
  backdrop-filter: var(--backdrop-filter);
  box-shadow: 0 0.25em 1.5em -0.25em rgba(0,0,0,0.5);
  padding: 0.75em;
`


const StyledRadioButtons = styled.div`
  display: flex;

  @media ( max-width: 36em ) {
    flex-direction: column;
  }
`

const StyledRadioButton = styled.button`
  appearance: none;
  background-color: transparent;
  border: 0;
  font-family: inherit;
  font-size: 1.125em;
  color: inherit;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  border-radius: calc(2 * var(--border-radius));
  background-color: rgba(255,255,255,0.5);
  box-shadow: 0 0.125em 0.5em -0.25em rgba(0,0,0,0.3);
  padding: 1em 1.5em;
  font-weight: 700;
  line-height: 1;
  cursor: pointer;
  i {

    font-style: normal;
    font-size: 0.75em;
    font-weight: 500;
    padding: 0.25em 0.5em;
    background-color: rgba(170,170,170,0.3);
    border-radius: var(--border-radius);
    line-height: 1;
    white-space: nowrap;
    color: var(--color-black);
  }
  &.is-active {
    background-color: var(--color-black);
    color: var(--color-white);
    i {
      background-color: rgba(255,255,255,0.6);
    }
  }
  @media ( max-width: 36em ) {
    &:not(:last-of-type) {
      margin-bottom: 0.75em;
    }
  }
  @media ( min-width: 36em ) {
    flex-direction: column;
    margin-bottom: 0;
    &:not(:last-of-type) {
      margin-right: 0.75em;
    }
    i {
      margin-top: 1em;
    }
  }
`

const RadioAlt = ( props ) => {

  const data = useStaticQuery(
    graphql`
      query {
        ambient: allDirectusTrack(filter: {album: {genre: {elemMatch: {name: {in: "Ambient"}}}}}) {
          edges {
            node {
              video_id
              title
              album {
                id
                directusId
                artist {
                  name
                  slug
                }
                link_apple
                link_amazon
                link_spotify
                link_soundcloud
                title
              }
            }
          }
        }
        classical: allDirectusTrack(filter: {album: {genre: {elemMatch: {name: {in: "Classical"}}}}}) {
          edges {
            node {
              video_id
              title
              album {
                id
                directusId
                artist {
                  name
                  slug
                }
                link_apple
                link_amazon
                link_spotify
                link_soundcloud
                title
              }
            }
          }
        }
        electronic: allDirectusTrack(filter: {album: {genre: {elemMatch: {name: {nin: ["Ambient", "Classical", "Hip-hop"]}}}}}) {
          edges {
            node {
              video_id
              title
              album {
                id
                directusId
                artist {
                  name
                  slug
                }
                link_apple
                link_amazon
                link_spotify
                link_soundcloud
                title
              }
            }
          }
        }
        hipHop: allDirectusTrack(filter: {album: {genre: {elemMatch: {name: {in: "Hip-hop"}}}}}) {
          edges {
            node {
              video_id
              title
              album {
                id
                directusId
                artist {
                  name
                  slug
                }
                link_apple
                link_amazon
                link_spotify
                link_soundcloud
                title
              }
            }
          }
        }
      }
    `
  )

  const { 
    loadAndPlayTracks,
    showPlayer
  } = usePlayer()

  const [currentStation, setCurrentStation] = useState(``)

  function getShuffledTracks( tracks ) {
    let tracksArray = []

    for ( let { node } of tracks ) {
      tracksArray.push( node )
    }
    
    return tracksArray.sort(function() {
      return .5 - Math.random()
    })

  }

  const ambientTracks = getShuffledTracks( data.ambient.edges ) || false
  const classicalTracks = getShuffledTracks( data.classical.edges ) || false
  const electronicTracks = getShuffledTracks( data.electronic.edges ) || false
  const hipHop = getShuffledTracks( data.hipHop.edges ) || false

  function playGenre( tracks, station ) {
    loadAndPlayTracks( tracks )
    setCurrentStation( station )
    showPlayer()
  }

  function handleGenreClick( tracks, station ) {
    playGenre( tracks, station)
  }

  useEffect(() => {

  })

  return (
    <StyledRadioWrap>
      <StyledRadioTitle><span>Radio</span></StyledRadioTitle>
      <StyledRadio {...props}>
        <StyledRadioButtons>
          <StyledRadioButton onClick={(e) => handleGenreClick( ambientTracks, `ambient` )} className={currentStation === `ambient` ? `is-active` : null}>Ambient <i>{ambientTracks.length} tracks</i></StyledRadioButton>
          <StyledRadioButton onClick={(e) => handleGenreClick( classicalTracks, `classical` )} className={currentStation === `classical` ? `is-active` : null}>Classical <i>{classicalTracks.length} tracks</i></StyledRadioButton>
          <StyledRadioButton onClick={(e) => handleGenreClick( electronicTracks, `electronic` )} className={currentStation === `electronic` ? `is-active` : null}>Electronic <i>{electronicTracks.length} tracks</i></StyledRadioButton>
          <StyledRadioButton onClick={(e) => handleGenreClick( hipHop, `hip-hop` )} className={currentStation === `hip-hop` ? `is-active` : null}>Hip-hop <i>{hipHop.length} tracks</i></StyledRadioButton>
        </StyledRadioButtons>
      </StyledRadio>
    </StyledRadioWrap>
  )
}

export default RadioAlt
