import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import styled, { keyframes } from "styled-components"
import RadioAlt from "components/radioAlt"


const pulse = keyframes`
  0%, 100% {
    opacity: 0.7
  }
  65% {
    opacity: 1
  }
`
const RadioPageWrapper = styled.div`
  position: relative;
  padding: 3em 0;
  min-height: 80vh;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -1px;
    bottom: 0;
    left: 50%;
    margin-left: -50vw;
    width: 100vw;
    background-image: url('/cover.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
    backdrop-filter: blur(16px) contrast(4) saturate(3);
    opacity: 0.5;
    animation: ${pulse} 5s ease infinite;
  }
`

const RadioPage = ({ data }) => {
  return (
    <RadioPageWrapper>
      <Helmet>
        <title>Radio | {data.site.siteMetadata.title}</title>
      </Helmet>
      <RadioAlt />
    </RadioPageWrapper>
  )
}

export default RadioPage

export const query = graphql`
    query {
        site {
            id
            siteMetadata {
                title
            }
        }
    }
`
